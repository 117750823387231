import React, { Component, Fragment } from "react";
import {TransitionGroup} from "react-transition-group";

import {
	Row,
	Col,
	Button,
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Nav,
	NavItem,
	ListGroup,
	ListGroupItem,
	Card,
	CardBody,
	CardHeader,
	CardLink,
	CardImg,
	NavLink,
	TabContent,
	TabPane,
	Progress,
	CardFooter,
	ButtonGroup,
} from "reactstrap";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const CLIENT = {
	sandbox: process.env.PAYPAL_CLIENT_ID_SANDBOX,
	production: process.env.PAYPAL_CLIENT_ID_PRODUCTION,
};

export default class WritingElements extends Component {
	constructor(props) {
		super(props);

		this.toggle2 = this.toggle2.bind(this);
		this.state = {
			activeTab2: "222",
			activeTab1: "11",
		};
	}

	toggle2(tab) {
		if (this.state.activeTab2 !== tab) {
			this.setState({
				activeTab2: tab,
			});
		}
	}

	toggle1(tab) {
		if (this.state.activeTab1 !== tab) {
			this.setState({
				activeTab1: tab,
			});
		}
	}

	render() {
		const { data } = this.state;

		return (
			<Fragment>
				<Helmet>
					<title>What Is PonoMap?</title>
					<meta name="description" content="PonoMap is a public database oriented around identifying and rewarding sustainable practices within communities. This about page provides great details about our mission and interests." />
					<link rel="canonical" href="https://ponomap.com/about" />
				</Helmet>
				<TransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}
				>
					<Card
						style={{
							zIndex: 1,
							opacity: 100,
							width: "100%",
							position: "auto",
							boxShadow: "0px 0px 0px 5px rgba(50,50,50, .8)",
							left: "auto",
						}}
					>
						<CardHeader style={{ textAlign: "center" }}>
							<h2 id="WHAT IS PONOMAP">What Is PonoMap?</h2>
						</CardHeader>
						<CardBody
							style={{
								backgroundColor: "#FFFFFF",
								fontSize: "22px",
							}}
						>
							{" "}
							<br />
							Wondering where you can be an eco warrior? Maybe you’d like to go plastic
							free, buy some reef safe sunscreen, or go to a farm-to-table restaurant
							as an eco conscious individual. With PonoMap, we are creating a one stop
							shop for anything and everything sustainable. What’s even better is that
							PonoMap is created by you!
							<br />
							<br />
							<strong style={{ color: "#003377" }}>
								PonoMap is a community built platform designed to capture every detail
								about sustainability and easily share it with one another.
							</strong>{" "}
							Don’t see a recycling bin on the map at your favorite beach access? Well,
							now you can be a part of propagating a sustainable future for the islands
							and share the best the islands' have to offer so that others may malama
							as well!
							<br />
							<br />
							The PonoMap program offers everyone to be active participants in
							sustainability in Hawaii. Businesses have an easy way to show their
							commitment to making sustainable choices for our oceans, islands and
							communities. Additionally, we network sustainable businesses together so
							that they can collaborate to further develop sustainability across
							Hawaii.{" "}
							<Link
								className="HoverToTeal"
								style={{ fontWeight: "700" }}
								to="/account"
							>
								Interested in becoming a PonoMap member?
							</Link>{" "}
							<br /> <br />
							<br />
							<b> Table Of Contents:</b>
							<br />
							<br />
							<small>
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();
										window.scrollTo({
											top: 0,
											behavior: "smooth",
										});
									}}
								>
									<li style={{ marginBottom: "10px" }}>WHAT IS PONOMAP?</li>
								</a>
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();
										document
											.getElementById("WHY IS SUSTAINABILITY IMPORTANT")
											.scrollIntoView({
												behavior: "smooth",
												block: "start",
												inline: "center",
											});
									}}
								>
									<li style={{ marginBottom: "10px" }}>
										WHY IS SUSTAINABILITY IMPORTANT?
									</li>
								</a>
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();
										document
											.getElementById("DEVELOPING ECOTOURISM AS A NEW NORMAL")
											.scrollIntoView({
												behavior: "smooth",
												block: "start",
												inline: "center",
											});
									}}
								>
									<li style={{ marginBottom: "10px" }}>
										DEVELOPING ECOTOURISM AS A NEW NORMAL
									</li>
								</a>
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();
										document
											.getElementById("IMPROVING SUSTAINABILITY IN YOUR HOME")
											.scrollIntoView({
												behavior: "smooth",
												block: "start",
												inline: "center",
											});
									}}
								>
									<li style={{ marginBottom: "10px" }}>
										IMPROVING SUSTAINABILITY IN YOUR HOME
									</li>
								</a>
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();
										document
											.getElementById("BE THE LEADERS IN SUSTAINABLE BUSINESS")
											.scrollIntoView({
												behavior: "smooth",
												block: "start",
												inline: "center",
											});
									}}
								>
									<li style={{ marginBottom: "10px" }}>
										BE THE LEADERS IN SUSTAINABLE BUSINESS
									</li>
								</a>
							</small>
							<br />
							<span>
								<h3>
									<br id="WHY IS SUSTAINABILITY IMPORTANT" />
									<br />
									<CardHeader style={{ textAlign: "center" }}>
										<h2>Why is sustainability important?</h2>
									</CardHeader>
									<br />
									Here in Hawaii we have several issues that you probably contribute to
									unconsciously through everyday activities that aren't necessarily good
									to the environment or community.
									<br />
									<br />
									<strong style={{ color: "#003377" }}>Reef Killing Sunscreen</strong>
									<br /> Just recently, January 2021, Hawaii enacted its ban on several
									reef harmful chemicals from sunscreens, many guests are oblivious of
									the disastrous effect of convenient spray aerosols have on our delicate
									reef systems.
									<br /> <br />
									<strong style={{ color: "#003377" }}>The Plastic Problem</strong>
									<br />
									In Hawaii, we import anywhere between 75%-90% of our food, most of
									which comes in plastic packaging. Of that plastic packaging, very
									little is recyclable and even less actually makes it into a legitimate
									recycling stream. Compounding that, all of Hawaii’s plastic recycling
									is exported, creating a double carbon footprint in shipping. We have
									solutions!
									<br id="DEVELOPING ECOTOURISM AS A NEW NORMAL" /> <br />
									<br />
									<CardHeader style={{ textAlign: "center" }}>
										<h2 style={{ position: "relative", top: "-5px" }}>
											{" "}
											Developing Ecotourism as a New Normal
										</h2>
									</CardHeader>
									<br />
									Are you a guest to paradise? Our community makes it clear that people
									want to learn to malama i ka aina. Now, you can be an eco-tourist, and
									invest in what you believe in! Using our map, you can support
									conscientious local businesses, curtail your impact on the islands
									environment, and find exciting opportunities to give back to the
									islands!
									<br id="IMPROVING SUSTAINABILITY IN YOUR HOME" />
									<br />
									<CardHeader style={{ textAlign: "center" }}>
										<h2 style={{ position: "relative", top: "-5px" }}>
											Improving Sustainability in your home
										</h2>
									</CardHeader>
									<br />
									Part of what PonoMap provides for our Hawaii residents is the network
									of other community members and businesses that can help you be more
									pono at your own home! Perhaps you just got an electric car and need
									<br /> <br />
									Maybe you’d like to start composting or hire a service to pick up your
									compost- PONOMAP! /Maybe you’re ready to get your home off-grid or more
									energy efficient- PonoMap!
									<br id="BE THE LEADERS IN SUSTAINABLE BUSINESS" />
									<br />
									<CardHeader style={{ textAlign: "center" }}>
										<h2>Be the Leaders in Sustainable Business</h2>
									</CardHeader>
									<br />
									For Businesses that are already doing the right thing, being listed on
									PonoMap is a must! Help our most conscious guests and residents support
									your sustainable business easily. Your listing will provide a full
									spectrum of all the eco-conscious and sustainable practices, products
									or services that you offer. Additionally you’ll be able to connect with
									other sustainable businesses to help one another to continuously set a
									high standard for doing Pono Business!
								</h3>
							</span>
							<br />
							<center>
								<h2>
									<Link to="/account">Add Sustainable Data Today</Link>
								</h2>
							</center>
						</CardBody>
					</Card>
				</TransitionGroup>
			</Fragment>
		);
	}
}
