import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import {
 MainNav,
 AboutNav,
 AdminNav,
} from "./NavItems";

import {
 IoIosInformationCircleOutline,
 IoIosCog,
} from "react-icons/io";

import { GiAtom } from "react-icons/gi";

import { GoMailRead } from "react-icons/go";

import {
 SiGooglecalendar,
 SiShopify,
} from "react-icons/si";

import { BiRestaurant } from "react-icons/bi";

import { AiTwotoneShop } from "react-icons/ai";

import { ImList2 } from "react-icons/im";

import { FcPlus } from "react-icons/fc";

import {
 GrMapLocation,
 GrTreeOption,
} from "react-icons/gr";

import { Link } from "react-router-dom";

import { VscAccount } from "react-icons/vsc";

class Nav extends Component {
 state = {};

 toggleMobileSidebar = () => {
  let {
   enableMobileMenu,
   setEnableMobileMenu,
  } = this.props;
  setEnableMobileMenu(!enableMobileMenu);
 };

 render() {
  return (
   <Fragment>
    <h5 className="app-sidebar__heading">Navigate</h5>
    <Link onClick={this.toggleMobileSidebar} to="/">
     <h4 style={{ height: window.innerHeight / 20 }}>
      <button
       className="gradientBtn"
       style={{
        width: "115%",
        left: "-20px",
        position: "relative",
       }}
      >
       <span
        className="metismenu-item"
        style={{
         position: "relative",
         top: "-3px",
         left: "3px",
        }}
       >
        <svg
         className="spin2"
         height="25px"
         width="25px"
        >
         <GiAtom />
        </svg>
        &nbsp;&nbsp;&nbsp;
       </span>
       <span
        className="sidebarLinks"
        style={{
         position: "relative",
         top: "1px",
         left: "15px",
        }}
       >
        Home
       </span>
      </button>
     </h4>
    </Link>
    <Link
     onClick={() => {
      document.getElementById(
       "MapDropDown"
      ).hidden = true;
      document.getElementById(
       "MapDropDown2"
      ).hidden = true;
      document.getElementById(
       "MapDropDown3"
      ).hidden = true;
      document.getElementById(
       "MapDropDown4"
      ).hidden = true;
      this.toggleMobileSidebar();
     }}
     to="/services?All"
    >
     <h4 style={{ height: window.innerHeight / 20 }}>
      <button
       className="gradientBtn"
       style={{
        width: "115%",
        left: "-20px",
        position: "relative",
       }}
      >
       <span
        className="metismenu-item"
        style={{
         position: "relative",
         top: "-3px",
         left: "3px",
        }}
       >
        <GrMapLocation />
        &nbsp;&nbsp;&nbsp;
       </span>
       <span
        className="sidebarLinks"
        style={{
         position: "relative",
         top: "1px",
         left: "15px",
        }}
       >
        Map
       </span>
      </button>
     </h4>
    </Link>{" "}
    <Link
     onClick={this.toggleMobileSidebar}
     to="/account"
    >
     <h4 style={{ height: window.innerHeight / 20 }}>
      <button
       className="gradientBtn"
       style={{
        width: "115%",
        left: "-20px",
        position: "relative",
       }}
      >
       <span
        className="metismenu-item"
        style={{
         position: "relative",
         top: "-3px",
         left: "3px",
        }}
       >
        <VscAccount />
        &nbsp;&nbsp;&nbsp;
       </span>
       <span
        className="sidebarLinks"
        style={{
         position: "relative",
         top: "1px",
         left: "15px",
        }}
       >
        Login
       </span>
      </button>
     </h4>
    </Link>
    <Link
     onClick={() => {
      document.getElementById(
       "MapDropDown"
      ).hidden = false;
      document.getElementById(
       "MapDropDown2"
      ).hidden = false;
      document.getElementById(
       "MapDropDown3"
      ).hidden = false;
      document.getElementById(
       "MapDropDown4"
      ).hidden = false;
      return false;
     }}
     to="#"
    >
     <h4 style={{ height: window.innerHeight / 20 }}>
      <button
       className="gradientBtn"
       style={{
        width: "115%",
        left: "-20px",
        position: "relative",
       }}
      >
       <span
        className="metismenu-item"
        style={{
         position: "relative",
         top: "-3px",
         left: "3px",
        }}
       >
        <ImList2 />
        &nbsp;&nbsp;&nbsp;
       </span>
       <span
        className="sidebarLinks"
        style={{
         position: "relative",
         top: "1px",
         left: "15px",
        }}
       >
        Location Types
       </span>
      </button>
     </h4>
    </Link>
    <Link
     onClick={() => {
      document.getElementById(
       "MapDropDown"
      ).hidden = true;
      document.getElementById(
       "MapDropDown2"
      ).hidden = true;
      document.getElementById(
       "MapDropDown3"
      ).hidden = true;
      document.getElementById(
       "MapDropDown4"
      ).hidden = true;
      this.toggleMobileSidebar();
     }}
     id="MapDropDown"
     hidden
     to="/services?All"
    >
     <h4 style={{ height: window.innerHeight / 20 }}>
      <button
       className="gradientBtn"
       style={{
        width: "115%",
        left: "-20px",
        position: "relative",
       }}
      >
       <span
        className="metismenu-item"
        style={{
         position: "relative",
         top: "-3px",
         left: "3px",
        }}
       >
        &nbsp;&nbsp;&nbsp; <GrMapLocation />
        &nbsp;&nbsp;&nbsp;
       </span>
       <span
        className="sidebarLinks"
        style={{
         position: "relative",
         top: "1px",
         left: "15px",
        }}
       >
        All
       </span>
      </button>
     </h4>
    </Link>
    <Link
     onClick={() => {
      document.getElementById(
       "MapDropDown"
      ).hidden = true;
      document.getElementById(
       "MapDropDown2"
      ).hidden = true;
      document.getElementById(
       "MapDropDown3"
      ).hidden = true;
      document.getElementById(
       "MapDropDown4"
      ).hidden = true;
      this.toggleMobileSidebar();

      if (window.location.pathname === "/services") {
       console.log("True");
       window.showParks();
      }
     }}
     id="MapDropDown2"
     hidden
     to="/services?Parks"
    >
     <h4 style={{ height: window.innerHeight / 20 }}>
      <button
       className="gradientBtn"
       style={{
        width: "115%",
        left: "-20px",
        position: "relative",
       }}
      >
       <span
        className="metismenu-item"
        style={{
         position: "relative",
         top: "-3px",
         left: "3px",
        }}
       >
        &nbsp;&nbsp;&nbsp; <GrTreeOption />
        &nbsp;&nbsp;&nbsp;
       </span>
       <span
        className="sidebarLinks"
        style={{
         position: "relative",
         top: "1px",
         left: "15px",
        }}
       >
        Parks
       </span>
      </button>
     </h4>
    </Link>
    <Link
     onClick={() => {
      document.getElementById(
       "MapDropDown"
      ).hidden = true;
      document.getElementById(
       "MapDropDown2"
      ).hidden = true;
      document.getElementById(
       "MapDropDown3"
      ).hidden = true;
      document.getElementById(
       "MapDropDown4"
      ).hidden = true;
      this.toggleMobileSidebar();
     }}
     id="MapDropDown4"
     hidden
     to="/services?Food"
    >
     <h4 style={{ height: window.innerHeight / 20 }}>
      <button
       className="gradientBtn"
       style={{
        width: "115%",
        left: "-20px",
        position: "relative",
       }}
      >
       <span
        className="metismenu-item"
        style={{
         position: "relative",
         top: "-3px",
         left: "3px",
        }}
       >
        &nbsp;&nbsp;&nbsp; <BiRestaurant />
        &nbsp;&nbsp;&nbsp;
       </span>
       <span
        className="sidebarLinks"
        style={{
         position: "relative",
         top: "1px",
         left: "15px",
        }}
       >
        Food
       </span>
      </button>
     </h4>
    </Link>
    <Link
     id="MapDropDown3"
     onClick={() => {
      document.getElementById(
       "MapDropDown"
      ).hidden = true;
      document.getElementById(
       "MapDropDown2"
      ).hidden = true;
      document.getElementById(
       "MapDropDown3"
      ).hidden = true;
      document.getElementById(
       "MapDropDown4"
      ).hidden = true;
      this.toggleMobileSidebar();
     }}
     hidden
     to="/services?Business"
    >
     <h4 style={{ height: window.innerHeight / 20 }}>
      <button
       className="gradientBtn"
       style={{
        width: "115%",
        left: "-20px",
        position: "relative",
       }}
      >
       <span
        className="metismenu-item"
        style={{
         position: "relative",
         top: "-3px",
         left: "3px",
        }}
       >
        &nbsp;&nbsp;&nbsp; <AiTwotoneShop />
        &nbsp;&nbsp;&nbsp;
       </span>
       <span
        className="sidebarLinks"
        style={{
         position: "relative",
         top: "1px",
         left: "15px",
        }}
       >
        Business
       </span>
      </button>
     </h4>
    </Link>
    <Link
     onClick={this.toggleMobileSidebar}
     to="/account"
    >
     <h4 style={{ height: window.innerHeight / 20 }}>
      <button
       className="gradientBtn"
       style={{
        width: "115%",
        left: "-20px",
        position: "relative",
       }}
      >
       <span
        className="metismenu-item"
        style={{
         position: "relative",
         top: "-3px",
         left: "3px",
        }}
       >
        <FcPlus />
        &nbsp;&nbsp;&nbsp;
       </span>
       <span
        className="sidebarLinks"
        style={{
         position: "relative",
         top: "1px",
         left: "15px",
        }}
       >
        Add Location
       </span>
      </button>
     </h4>
    </Link>
    <Link
     onClick={this.toggleMobileSidebar}
     to="/about"
    >
     <h4 style={{ height: window.innerHeight / 20 }}>
      <button
       className="gradientBtn"
       style={{
        width: "115%",
        left: "-20px",
        position: "relative",
       }}
      >
       <span
        className="metismenu-item"
        style={{
         position: "relative",
         top: "-3px",
         left: "3px",
        }}
       >
        <IoIosInformationCircleOutline />
        &nbsp;&nbsp;&nbsp;
       </span>
       <span
        className="sidebarLinks"
        style={{
         position: "relative",
         top: "1px",
         left: "15px",
        }}
       >
        About
       </span>
      </button>
     </h4>
    </Link>
    <Link
     onClick={this.toggleMobileSidebar}
     to="/contact"
    >
     <h4 style={{ height: window.innerHeight / 20 }}>
      <button
       className="gradientBtn"
       style={{
        width: "115%",
        left: "-20px",
        position: "relative",
       }}
      >
       <span
        className="metismenu-item"
        style={{
         position: "relative",
         top: "-3px",
         left: "3px",
        }}
       >
        <GoMailRead />
        &nbsp;&nbsp;&nbsp;
       </span>
       <span
        className="sidebarLinks"
        style={{
         position: "relative",
         top: "1px",
         left: "15px",
        }}
       >
        Contact Us
       </span>
      </button>
     </h4>
    </Link>
    {/*
        <h5 className="app-sidebar__heading">Forms</h5>
        <MetisMenu content={FormsNav} onSelected={this.toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>

        <h5 className="app-sidebar__heading">Charts</h5>
        <MetisMenu content={ChartsNav} onSelected={this.toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
   */}
   </Fragment>
  );
 }

 isPathActive(path) {
  return this.props.location.pathname.startsWith(path);
 }
}
const mapStateToProps = (state) => ({
 enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
 setEnableMobileMenu: (enable) =>
  dispatch(setEnableMobileMenu(enable)),
});
export default connect(
 mapStateToProps,
 mapDispatchToProps
)(withRouter(Nav));
