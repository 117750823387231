import React, { Component, Fragment, useState, useEffect, useRef } from "react";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

var GMAPAPI = process.env.REACT_APP_GMAPAPI;

function SimpleMap() {
	const firebaseConfig = {
		apiKey: process.env.REACT_APP_FIREBASE,
		authDomain: "ponomap-c8faa.firebaseapp.com",
		projectId: "ponomap-c8faa",
		storageBucket: "ponomap-c8faa.appspot.com",
		messagingSenderId: "913918067006",
		appId: "1:913918067006:web:e94012e765578331cbec6f",
		measurementId: "G-E2P5QYL5V1",
	};

	const isInitialMount = useRef(true);

	const [gotMapData, setgotMapData] = useState("1");
	const [loadStage, setloadStage] = useState("1");
	const [loadedSnapshotData, setloadedSnapshotData] = useState("");

	if (!firebase.apps.length) {
		firebase.initializeApp(firebaseConfig);
	}

	let markers = [];
	let map2;

	useEffect(() => {
		if (isInitialMount.current === true) {
			//    console.log("LoadStage: " + loadStage);
			if (loadStage === "1") {
				handleApiLoaded() & setloadStage("2");
			}
			if (loadStage === "2") {
				if (gotMapData === "2") {
					handleApiLoaded() & setloadStage("3");
				}
			}
			if (loadStage === "3") {
				isInitialMount.current = false;
			}
		}
	});

	function handleApiLoaded(map, maps) {
		const iconBase = "https://maps.google.com/mapfiles/";
		const icons = {
			park: {
				name: "Park",
				icon: {
					url: "/images/parksIcon2.png",
					scaledSize: new google.maps.Size(45, 45),
				},
			},
			info: {
				name: "Info",
				icon: {
					url: "/images/foodIcon5.png",
					scaledSize: new google.maps.Size(45, 45),
				},
			},
			food: {
				name: "Food",
				icon: {
					url: "/images/foodIcon5.png",
					scaledSize: new google.maps.Size(45, 45),
				},
			},
			generic: {
				name: "Generic",
				icon: {
					url: "/images/genericIcon.png",
					scaledSize: new google.maps.Size(45, 45),
				},
			},
			business: {
				name: "Business",
				icon: iconBase + "arrow.png",
			},
		};
		function initMap() {
			window.map = map2 = new google.maps.Map(document.getElementById("map"), {
				center: {
					lat: 20.81,
					lng: 203.62,
				},
				zoom: window.innerWidth > 1000 ? 10 : 9,
				icon: icons,
				styles: [
					{
						elementType: "geometry",
						stylers: [
							{
								color: "#242f3e",
							},
						],
					},
					{
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#746855",
							},
						],
					},
					{
						elementType: "labels.text.stroke",
						stylers: [
							{
								color: "#242f3e",
							},
						],
					},
					{
						featureType: "administrative.land_parcel",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "administrative.locality",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#d59563",
							},
						],
					},
					{
						featureType: "administrative.neighborhood",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "poi",
						elementType: "labels.text",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "poi",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#d59563",
							},
						],
					},
					{
						featureType: "poi.park",
						elementType: "geometry",
						stylers: [
							{
								color: "#263c3f",
							},
						],
					},
					{
						featureType: "poi.park",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#6b9a76",
							},
						],
					},
					{
						featureType: "road",
						elementType: "geometry",
						stylers: [
							{
								color: "#38414e",
							},
						],
					},
					{
						featureType: "road",
						elementType: "geometry.stroke",
						stylers: [
							{
								color: "#212a37",
							},
						],
					},
					{
						featureType: "road",
						elementType: "labels",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "road",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#9ca5b3",
							},
						],
					},
					{
						featureType: "road.arterial",
						elementType: "labels",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "geometry",
						stylers: [
							{
								color: "#746855",
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "geometry.stroke",
						stylers: [
							{
								color: "#1f2835",
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "labels",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#f3d19c",
							},
						],
					},
					{
						featureType: "road.local",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "transit",
						elementType: "geometry",
						stylers: [
							{
								color: "#2f3948",
							},
						],
					},
					{
						featureType: "transit.station",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#d59563",
							},
						],
					},
					{
						featureType: "water",
						elementType: "geometry",
						stylers: [
							{
								color: "#17263c",
							},
						],
					},
					{
						featureType: "water",
						elementType: "labels.text",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "water",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#515c6d",
							},
						],
					},
					{
						featureType: "water",
						elementType: "labels.text.stroke",
						stylers: [
							{
								color: "#17263c",
							},
						],
					},
				],
			});

			let features = [];
			let markers = [];
			let marker = [];
			// Deletes all markers in the array by removing references to them.

			try {
				const loadsnapshot = async () => {
					let concData = [];
					const snapshot = await firebase
						.firestore()
						.collection("LiveMapData")
						.get();
					snapshot.forEach(async function (doc) {
						concData = concData.concat(doc.data());
					});
					setloadedSnapshotData(concData) & setgotMapData("2");
				};
				loadsnapshot()
					.then(async () => {
						if (loadedSnapshotData != "") {
							for (
								var i = 0;
								i < JSON.parse(JSON.stringify(loadedSnapshotData)).length;
								i++
							) {
								let splitCoordsArray = String(
									JSON.parse(JSON.stringify(loadedSnapshotData))[i].GMapCoords
								).split(",");

								let decideFeatureIcon = "";
								console.log(JSON.parse(JSON.stringify(loadedSnapshotData))[i].Category);

								if (
									JSON.parse(JSON.stringify(loadedSnapshotData))[i].Category.includes(
										"Park"
									)
								) {
									decideFeatureIcon = "park";
								} else if (
									JSON.parse(JSON.stringify(loadedSnapshotData))[i].Category ===
										"restaurant" ||
									JSON.parse(JSON.stringify(loadedSnapshotData))[i].Category.includes(
										"Deli"
									)
								) {
									decideFeatureIcon = "food";
								} else {
									decideFeatureIcon = "generic";
								}

								let features = [
									{
										position: new google.maps.LatLng(
											splitCoordsArray[0],
											splitCoordsArray[1]
										),
										type: decideFeatureIcon,
										property: JSON.parse(JSON.stringify(loadedSnapshotData))[i].Category,
										property2: JSON.parse(JSON.stringify(loadedSnapshotData))[i].Public,
										content:
											"<b>" +
											JSON.parse(JSON.stringify(loadedSnapshotData))[i].Title +
											`</b><br /> <a href="https://www.google.com/search?q=${encodeURIComponent(
												String(JSON.parse(JSON.stringify(loadedSnapshotData))[i].Location) +
													JSON.parse(JSON.stringify(loadedSnapshotData))[i].Title
											).replace(" ", "+")}"` +
											">" +
											JSON.parse(JSON.stringify(loadedSnapshotData))[i].Location +
											"</a><br />" +
											JSON.parse(JSON.stringify(loadedSnapshotData))[i].Description,

										title: JSON.parse(JSON.stringify(loadedSnapshotData))[i].Title,

										label: JSON.parse(JSON.stringify(loadedSnapshotData))[i].Title,
									},
								];

								var infowindow = new google.maps.InfoWindow({
									content: "",
								});

								// Create markers.
								features.forEach(function (feature, index) {
									marker = new google.maps.Marker({
										position: feature.position,
										content: feature.content,
										title: feature.label,
										property: feature.property,
										property2: feature.property2,
										icon: icons[feature.type].icon,
										map: window.map,
									});

									markers.unshift(marker);
									//      console.log(markers[index].content);
								});

								markers.forEach((ele, index) => {
									markers[index].addListener("click", function () {
										infowindow.setContent("<div>" + markers[index].content + "</div>");

										infowindow.open(window.map, markers[index]);
									});
								});

								//set to All
								window.setMapOnAll = function setMapOnAll(map2) {
									for (let i = 0; i < markers.length; i++) {
										markers[i].setMap(map2);
									}
								};

								//set to Food
								window.setMapOnParks = function setMapOnAll(map2) {
									for (let i = 0; i < markers.length; i++) {
										//    console.log(markers[i].property);

										if (markers[i].property.includes("Park")) {
											markers[i].setMap(map2);
										}
									}
								};
								window.showParks = function showMarkers() {
									clearMarkers();
									setMapOnParks(map2);
								};

								//set to Food
								window.setMapOnFood = function setMapOnAll(map2) {
									for (let i = 0; i < markers.length; i++) {
										//   console.log(markers[i].property);
										if (
											markers[i].property.includes("Food") ||
											markers[i].property.includes("Restaurant") ||
											markers[i].property.includes("Deli")
										) {
											markers[i].setMap(map2);
										}
									}
								};
								window.showFood = function showMarkers() {
									clearMarkers();
									setMapOnFood(map2);
								};

								//set to Businesses
								window.setMapOnBusinesses = function setMapOnAll(map2) {
									for (let i = 0; i < markers.length; i++) {
										//   console.log(markers[i].property);
										if (markers[i].property2.includes("1")) {
											markers[i].setMap(map2);
										}
									}
								};
								window.showBusinesses = function showMarkers() {
									clearMarkers();
									setMapOnBusinesses(map2);
								};

								window.clearMarkers = function clearMarkers() {
									//  console.log(markers.length);
									setMapOnAll(null);
								};
							}

							window.showMarkers = function showMarkers() {
								// console.log(markers.length);
								setMapOnAll(map2);
							};

							window.deleteMarkers = function deleteMarkers() {
								clearMarkers();
								markers = [];
							};

							/// set clear features function
						}
					})
					.catch((err) => {
						// console.log(err);
					});
			} catch (error) {
				//   console.log(error);
			}
		}

		//  console.log("GMap Load");

		initMap(window.map);
	}

	function markerClick() {
		window.location.pathname = "/account";
	}

	function componentWillUnmount() {}
	let latLong = "20.8474754, -156.6349604".split(",");
	const AnyReactComponent = ({ text }) => (
		<button
			className="maptxt"
			style={{ height: "25px" }}
			type="button"
			onClick={markerClick}
		>
			{text}
		</button>
	);
	return (
		<div
			id="map"
			style={{
				height: "500px",
				width: "100%",
				boxShadow: "0px 0px 0px 5px rgba(50,50,50, .8)",
			}}
		></div>
	);
}

export default SimpleMap;
