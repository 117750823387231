import React, { Fragment } from "react";
import cx from "classnames";
import { findDOMNode } from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { TransitionGroup } from "react-transition-group";

import { Popover, Nav, NavLink, Col, Row, NavItem, Button } from "reactstrap";

import HeaderLogo from "../AppLogo";

import MegaMenu from "./Components/MegaMenu";
import Login from "../../Login/Login";

import SendToGoogleAnalytics from "./Components/analytics";

import HeaderDots from "./Components/HeaderDots";

import LoginRedirect from "../../Login/LoginRedirect";

import {
	setEnableMobileMenu,
	setEnableMobileMenuSmall,
} from "../../reducers/ThemeOptions";

class Header extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			url: "",
		};
		this.updateState = this.updateState.bind(this);
		this.toggleMobileSmall = this.toggleMobileSmall.bind(this);
	}
	updateState() {
		this.setState({
			url: window.location.path,
		});
	}

	UNSAFE_componentWillMount() {
		setInterval(() => {
			if (window.location.pathname === "/account") {
				if (document.getElementById("AccountHeaderButton"))
					document.getElementById("AccountHeaderButton").style.opacity = 0;

				setTimeout(() => {
					if (document.getElementById("AccountHeaderButton"))
						document.getElementById("AccountHeaderButton").hidden = true;
				}, 1000);
			} else {
				if (document.getElementById("AccountHeaderButton"))
					document.getElementById("AccountHeaderButton").style.opacity = 1;
				if (document.getElementById("AccountHeaderButton"))
					document.getElementById("AccountHeaderButton").hidden = false;
			}

			if (window.location.pathname === "/") {
				document.getElementById("HomeHeaderButton").style.opacity = 0;
				setTimeout(() => {
					document.getElementById("HomeHeaderButton").hidden = true;
				}, 1000);
			} else {
				if (document.getElementById("HomeHeaderButton"))
					document.getElementById("HomeHeaderButton").style.opacity = 1;

				if (document.getElementById("HomeHeaderButton"))
					document.getElementById("HomeHeaderButton").hidden = false;
			}
		}, 1000);

		window.addEventListener("hashchange", this.updateState, false);
		this.setState({
			url: window.location.path,
		});

		document.addEventListener("click", this.closePopupOnClick.bind(this), false);
	}

	componentWillUnmount() {
		document.removeEventListener(
			"click",
			this.closePopupOnClick.bind(this),
			false
		);
	}

	closePopupOnClick(event) {
		let { enableMobileMenuSmall, setEnableMobileMenuSmall } = this.props;
		if (enableMobileMenuSmall) {
			if (this.state.mobileActive === true) {
				console.log(String(event.target.id));
				if (
					String(event.target.className) === "[object SVGAnimatedString]" ||
					String(event.target.id) === "MobileMenuID" ||
					String(event.target.id) === "btn-icon-wrapper" ||
					String(event.target.id) === "MobileMenuID" ||
					String(event.target.className) === "MobileMenuID" ||
					String(event.target.id) === "MobileMenuIcon"
				) {
					console.log("Yes");
				} else {
					console.log(String(event.target.id));
					this.toggleMobileSmall();
				}
			} else {
				this.setState({ mobileActive: false });
			}
			this.setState({ mobileActive: true });
		} else {
		}
	}

	toggleMobileSmall() {
		let { enableMobileMenuSmall, setEnableMobileMenuSmall } = this.props;
		setEnableMobileMenuSmall(!enableMobileMenuSmall);
		this.setState({ mobileActive: false });
	}

	render() {
		let { headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow } =
			this.props;
		return (
			<Fragment>
				<TransitionGroup
					component="div"
					className={cx("app-header", headerBackgroundColor, {
						"header-shadow": enableHeaderShadow,
					})}
					transitionName="HeaderAnimation"
					transitionAppear={true}
					transitionAppearTimeout={700}
					transitionEnter={true}
					transitionEnterTimeout={700}
					transitionLeave={false}
				>
					<HeaderLogo />

					<div
						className={cx("app-header__content", {
							"header-mobile-open": enableMobileMenuSmall,
						})}
					>
						<div className="app-header-left">
							<MegaMenu />
						</div>

						<span
							className="headerInfo1"
							style={{
								height: "auto",
								position: "relative",
								fontFamily: '"Montserrat", sans-serif',
								fontWeight: 600,
								fontSize: "22px",
								left: "auto",
							}}
						>
							<Link to="/account">Map Your Pono-venture Today!</Link>
						</span>
						<div className="app-header-right">
							<a id="AccountHeaderButton" href={`/account`}>
								<Button
									className="btn-icon-horizontal btn-transition app-header-right"
									outline
									color="dark"
								>
									<i
										className="fa fa-home"
										style={{
											fontSize: "20px",
											alignContent: "center",
										}}
									></i>
									&nbsp;Account
								</Button>
							</a>
							&nbsp;
							<Button
								id="HomeHeaderButton"
								className="btn-icon-horizontal btn-transition app-header-right"
								outline
								color="dark"
							>
								<i
									className="fa fa-home"
									style={{
										fontSize: "20px",
										alignContent: "center",
									}}
								></i>
								&nbsp;Home
							</Button>
							<span style={{ width: "15px" }}></span>
						</div>
					</div>
				</TransitionGroup>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
	closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
	headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
	enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({
	setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
	setEnableMobileMenuSmall: (enable) =>
		dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
