import React, { Fragment } from "react";
import {TransitionGroup} from "react-transition-group";
import "../AppHeader/Components/analytics";
import mauisat from "../../assets/images/logo512.png";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickX = this.handleClick.bind(this);

    this.state = {
      sideBarVar: "1",
      redirect: false,
    };
  }

  runLoadSite() {
    document.getElementById("fadeIn").className = "landingContent fadeOut";

    setTimeout(() => {
      window.location.pathname = "/dashboards/";
    }, 500);
  }

  handleClick() {}

  setRedirect = () => {
    this.setState({
      redirect: true,
    });
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };
  render() {
    console.log(window.location.pathname);
    if (window.location.pathname === "/") {
      return (
        <Fragment>
          <Helmet>
            <title>PonoMap.com: Welcome Page</title>
            <meta
              name="description"
              content="Welcome to PonoMap, a community resource for everything sustainable in Hawaii."
            />
            <link rel="canonical" href="https://ponomap.com" />
          </Helmet>
          <div
            onClick={() =>
              (document.getElementById("fadeIn").className = "landingContent fadeOut") &
              setTimeout(() => {
                this.setRedirect();
              }, 500)
            }
          >
            {this.renderRedirect()}
            <TransitionGroup
              component="span"
              transitionName="MainAnimation4"
              transitionAppear={true}
              transitionAppearTimeout={700}
              transitionEnter={false}
              transitionLeave={false}
            >
              <span
                style={{ position: "absolute", zIndex: 2, width: "100%", height: "100vh" }}
                id="fadeIn"
                className="landingContent fadeIn "
              >
                {" "}
                <img
                  className="landingMaui"
                  style={{
                    width: "200px",
                    position: "relative",
                    top: "55px",
                    left: window.innerWidth / 2 - 100,
                  }}
                  alt="The Island Of Maui"
                  src={mauisat}
                ></img>
                <span>
                  <h2 style={{ margin: "35px", paddingTop: "55px" }}>
                    Welcome to Hawaii's Sustainable Location Finder
                  </h2>{" "}
                  <br />
                  <h3 style={{ margin: "25px", maxWidth: "500px", textAlign: "center" }}>
                    &nbsp;&nbsp;Your interactive tool for identifying sustainable locations and
                    business's that support eco friendly products and services on Hawaii's islands.
                    <br />
                  </h3>
                  <br />
                  <h4>
                    <Link style={{ color: "white" }} to="/">
                      Click Anywhere To Enter
                    </Link>
                  </h4>
                </span>
                <br />
                <br />
              </span>
            </TransitionGroup>
          </div>
        </Fragment>
      );
    }
  }
}

export default LandingPage;
