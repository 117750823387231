import React, { Fragment, lazy, Suspense, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import { withRouter, Switch, Route, Redirect, Link } from "react-router-dom";

import Loader from "react-loaders";

import { ToastContainer } from "react-toastify";

// Pages
import CheckVersions from "./checkVersions";

import ResizeDetector from "react-resize-detector";

import { unregister } from "../../serviceWorker";

import AppMain from "../../Layout/AppMain";

import "firebase/storage";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/performance";

import firebase from "firebase/app";

import LandingPage from "../../Layout/AppMain/LandingPage";

const HomeDashboard = lazy(() =>
	retry(() => import("../../Pages/Dashboards/Home/"))
);

const AccountPage = lazy(() => import("../../Pages/Dashboards/Account/"));

const DynamicPage = lazy(() =>
	import("../Dashboards/DynamicPage/dynamicPage.js")
);

import Contact from "../../Pages/Dashboards/Contact/";
import Writing from "../../Pages/Dashboards/Writing/";
import Services from "../../Pages/Dashboards/Services/";

import Privacy from "../../Pages/Dashboards/PrivacyPolicy/";
import Terms from "../../Pages/Dashboards/TermsOfService/";
// Layout

import AppHeader from "../../Layout/AppHeader/";
import AppSidebar from "../../Layout/AppSidebar/";

// Theme Options
import ThemeOptions from "../../Layout/ThemeOptions/";

import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	TelegramShareButton,
	WhatsappShareButton,
	RedditShareButton,
	EmailShareButton,
	TumblrShareButton,
	FacebookIcon,
	TwitterIcon,
	LinkedinIcon,
	TelegramIcon,
	WhatsappIcon,
	RedditIcon,
	TumblrIcon,
	EmailIcon,
} from "react-share";

function retry(fn, retriesLeft = 5, interval = 1000) {
	return new Promise((resolve, reject) => {
		fn()
			.then(resolve)
			.catch((error) => {
				setTimeout(() => {
					if (retriesLeft === 1) {
						// reject('maximum retries exceeded');
						reject(error);
						return;
					}

					// Passing on "reject" is the important part
					retry(fn, retriesLeft - 1, interval).then(resolve, reject);
				}, interval);
			});
	});
}

let shareUrl = "https://ponomap.com/";
let title = "Join PonoMap.com in generating sustainable information.";

class Main extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			closedSmallerSidebar: false,
			hasLoaded: 1,
			gotRoutes: null,
		};
	}

	UNSAFE_componentWillMount() {
		history.pushState = ((f) =>
			function pushState() {
				var ret = f.apply(this, arguments);
				window.dispatchEvent(new Event("pushstate"));
				window.dispatchEvent(new Event("locationchange"));
				return ret;
			})(history.pushState);

		history.replaceState = ((f) =>
			function replaceState() {
				var ret = f.apply(this, arguments);
				window.dispatchEvent(new Event("locationchange"));
				return ret;
			})(history.replaceState);

		window.addEventListener("popstate", () => {
			window.dispatchEvent(new Event("locationchange"));
		});
		const defaultAnalytics = firebase.analytics();
		firebase.performance();
		this.toggle1();

		window.addEventListener("locationchange", this.toggle1, false);

		document.body.addEventListener("click", async function (e) {
			const cityRef = firebase.firestore().collection("totalClicks").doc("value");

			try {
				await firebase.firestore().runTransaction(async (t) => {
					const doc = await t.get(cityRef);

					const newPopulation = doc.data().population + 1;
					t.update(cityRef, { population: newPopulation });
				});
			} catch (e) {
				console.log("Transaction failure:", e);
			}
		});
	}

	componentWillUnmount() {
		document.removeEventListener("popstate", this.toggle1.bind(this), false);
	}

	async toggle1() {
		window.scrollTo(0, 0);
		const cityRef = firebase.firestore().collection("totalHits").doc("value");

		try {
			await firebase.firestore().runTransaction(async (t) => {
				const doc = await t.get(cityRef);

				const newPopulation = doc.data().population + 1;
				t.update(cityRef, { population: newPopulation });
			});
			const auth = firebase.auth();

			if (auth.currentUser) {
				async function sendRequest(props) {
					//Emulator local url for development:
					let fetchURL = "";
					let useEmulator = false;
					const urlLocal = `http://localhost:5001/ponomap-c8faa/us-central1/confirmUserPoints`;

					// Quickly Toggle Between Emulator & Live Functions (Detects Localhost)
					//Live  url:
					const urlLive =
						"https://us-central1-ponomap-c8faa.cloudfunctions.net/confirmUserPoints";

					if (useEmulator && window.location.hostname.includes("localhost")) {
						fetchURL = urlLocal;
					} else {
						fetchURL = urlLive;
					}

					//Send Details
					const rawResponse = await fetch(fetchURL, {
						method: "POST",
						mode: "cors",
						headers: new Headers({
							"Content-Type": "application/json",
							Accept: "application/json",
							HeaderTokens: JSON.stringify({
								refreshToken: auth.currentUser.refreshToken,
								authDomain: auth.currentUser.authDomain,
								uid: auth.currentUser.uid,
								email: auth.currentUser.email,
								hostname: auth.currentUser.hostname,
								hostname2: window.location.hostname,
							}),
						}),
						body: JSON.stringify({
							UUID: auth.currentUser.uuid,
						}),
					});
					console.log(await rawResponse.json());
				}
				sendRequest();
			}
		} catch (e) {
			console.log("Transaction failure:", e);
		}

		let concData = [];
		let concData2 = [];
		let concData3 = [];
		try {
			const firebaseConfig = {
				apiKey: process.env.REACT_APP_FIREBASE,
				authDomain: "ponomap-c8faa.firebaseapp.com",
				projectId: "ponomap-c8faa",
				storageBucket: "ponomap-c8faa.appspot.com",
				messagingSenderId: "913918067006",
				appId: "1:913918067006:web:e94012e765578331cbec6f",
				measurementId: "G-E2P5QYL5V1",
			};
			if (!firebase.apps.length) {
				firebase.initializeApp(firebaseConfig);
			}
			const snapshot = await firebase.firestore().collection("version").get();

			snapshot.forEach(async function (doc) {
				concData = doc.data();
				console.log(concData.version);
				if (concData.version) {
					if (!localStorage.getItem("appVersion")) {
						localStorage.setItem("appVersion", concData.version);
					} else if (localStorage.getItem("appVersion") != concData.version) {
						if (caches) {
							caches.keys().then(function (names) {
								for (let name of names) caches.delete(name);
							});
							localStorage.setItem("appVersion", concData.version);
						}
						unregister();
					}
				}
			});
		} catch (error) {}
	}

	render() {
		if (this.state.gotRoutes === null) {
			let dbData = {};
			firebase
				.firestore()
				.collection("LiveMapData")
				.onSnapshot((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						var key = doc.id;
						var data = doc.data();
						data["key"] = key;
						dbData[key] = data;
					});
					Object.values(dbData).forEach((el) => {
						console.log(el);
					});
					this.setState({ gotRoutes: dbData });
				});
		}

		let {
			colorScheme,
			enableFixedHeader,
			enableFixedSidebar,
			enableFixedFooter,
			enableClosedSidebar,
			closedSmallerSidebar,
			enableMobileMenu,
			enablePageTabsAlt,
		} = this.props;

		return (
			<ResizeDetector
				handleWidth
				render={({ width }) => (
					<Fragment>
						<div
							className={cx(
								"app-container app-theme-" + colorScheme,
								{ "fixed-header": enableFixedHeader },
								{ "fixed-sidebar": enableFixedSidebar || width < 1250 },
								{ "fixed-footer": enableFixedFooter },
								{ "closed-sidebar": enableClosedSidebar || width < 1250 },
								{
									"closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
								},
								{ "sidebar-mobile-open": enableMobileMenu },
								{ "body-tabs-shadow-btn": enablePageTabsAlt }
							)}
						>
							<Switch>
								<Suspense
									fallback={
										<div className="loader-container">
											<div className="loader-container-inner">
												<div className="loader-wrapper d-flex justify-content-center align-items-center fadeIn">
													<Loader
														style={{
															transform: "scale(1.1)",
															top: "-100px",
															position: "relative",
															display: "center",
														}}
														color="#28bbDD"
														type="ball-zig-zag-deflect"
													/>{" "}
													<Loader
														style={{
															transform: "scale(2.5)",
															top: "-100px",
															position: "relative",
															display: "center",
														}}
														color="#28bbBB"
														type="ball-zig-zag-deflect"
													/>
												</div>
												<div className="text-center" style={{ color: "white" }}>
													Loading PonoMap <br />
												</div>
												<br />
											</div>
										</div>
									}
								>
									<ThemeOptions />
									<AppHeader />
									<div className="app-main">
										<AppSidebar />
										<div className="app-main__outer">
											<div className="app-main__inner">
												<Route exact path={`/`} component={HomeDashboard} />
												<Route path={`/services`} component={Services} />
												<Route path={`/account`} component={AccountPage} />
												<Route path={`/contact`} component={Contact} />
												<Route path={`/about`} component={Writing} />
												<Route path={`/privacy`} component={Privacy} />
												<Route path={`/termsofservice`} component={Terms} />

												{this.state.gotRoutes &&
													Object.values(this.state.gotRoutes).map((el) => {
														console.log(el.Title.replace(/\s/g, ""))
														return (
															<Route key={`RouteKey_${el.Title.replace(/\s/g, "")}`} path={`/${el.Title.replace(/\s/g, "")}`}>
																<DynamicPage key={`PageKey_${el.Title.replace(/\s/g, "")}`} props={el} />
															</Route>
														);
													})}

												<div id="footer">
													<span
														style={{
															width: "100%",
															textAlign: "center",
															float: "center",
															position: "relative",
															bottom: "0",
														}}
													>
														<div
															style={{
																width: "100%",
																textAlign: "center",
																float: "center",
																position: "relative",
																bottom: "-10px",
															}}
														>
															&nbsp;
															<b>PonoMap.com: Hawaii's Sustainable Data Resource</b>
															<br />
															<span className="Demo__some-network">
																<FacebookShareButton
																	url={shareUrl}
																	quote={title}
																	className="Demo__some-network__share-button"
																>
																	<FacebookIcon size={32} round />
																</FacebookShareButton>
															</span>
															&nbsp;
															<span className="Demo__some-network">
																<TwitterShareButton
																	url={shareUrl}
																	title={title}
																	className="Demo__some-network__share-button"
																>
																	<TwitterIcon size={32} round />
																</TwitterShareButton>
															</span>
															&nbsp;
															<span className="Demo__some-network">
																<TelegramShareButton
																	url={shareUrl}
																	title={title}
																	className="Demo__some-network__share-button"
																>
																	<TelegramIcon size={32} round />
																</TelegramShareButton>
															</span>
															&nbsp;
															<span className="Demo__some-network">
																<WhatsappShareButton
																	url={shareUrl}
																	title={title}
																	separator=":: "
																	className="Demo__some-network__share-button"
																>
																	<WhatsappIcon size={32} round />
																</WhatsappShareButton>
															</span>
															&nbsp;
															<span className="Demo__some-network">
																<LinkedinShareButton
																	url={shareUrl}
																	className="Demo__some-network__share-button"
																>
																	<LinkedinIcon size={32} round />
																</LinkedinShareButton>
															</span>
															&nbsp;
															<span className="Demo__some-network">
																<RedditShareButton
																	url={shareUrl}
																	title={title}
																	windowWidth={660}
																	windowHeight={460}
																	className="Demo__some-network__share-button"
																>
																	<RedditIcon size={32} round />
																</RedditShareButton>
															</span>
															&nbsp;
															<span className="Demo__some-network">
																<TumblrShareButton
																	url={shareUrl}
																	title={title}
																	className="Demo__some-network__share-button"
																>
																	<TumblrIcon size={32} round />
																</TumblrShareButton>
															</span>
															&nbsp;
															<span className="Demo__some-network">
																<EmailShareButton
																	url={shareUrl}
																	subject={title}
																	body="body"
																	className="Demo__some-network__share-button"
																>
																	<EmailIcon size={32} round />
																</EmailShareButton>
															</span>
														</div>
													</span>
												</div>
											</div>
										</div>
									</div>{" "}
									<span
										id="headerInfo2"
										className="headerInfo2"
										style={{
											zIndex: 998,
											position: "fixed",
											top: "0px",
										}}
									>
										<Link
											to="/account"
											onClick={(e) => {
												window.location.pathname === "/account"
													? window.location.reload()
													: null;
											}}
										>
											<img
												style={{ height: "60px", width: "60px" }}
												src="/images/logo512.png"
											></img>
										</Link>
									</span>
									<ToastContainer />
								</Suspense>
							</Switch>
						</div>
					</Fragment>
				)}
			/>
		);
	}
}

const mapStateToProp = (state) => ({
	colorScheme: state.ThemeOptions.colorScheme,
	enableFixedHeader: state.ThemeOptions.enableFixedHeader,
	enableMobileMenu: state.ThemeOptions.enableMobileMenu,
	enableFixedFooter: state.ThemeOptions.enableFixedFooter,
	enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
	enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
	enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(Main));
