import React, { Component, Fragment, setState } from "react";

import { TransitionGroup } from "react-transition-group";

import SimpleMap from "../Home/GMap.js";

import {
	Row,
	Col,
	Button,
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Nav,
	NavItem,
	ListGroup,
	ListGroupItem,
	Card,
	CardBody,
	CardHeader,
	CardImg,
	NavLink,
	TabContent,
	TabPane,
	Progress,
	CardFooter,
	ButtonGroup,
} from "reactstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

export default class CRMDashboard2 extends Component {
	constructor(props) {
		super(props);

		this.toggle2 = this.toggle2.bind(this);
		this.state = {
			activeTab2: "222",
			activeTab1: "11",
			activeMapView: "All",
			allListings: null,
		};
	}

	componentDidMount() {
		let params = window.location.search;
		let sideBarLocTypeAllButton = document.getElementById("MapDropDown");
		let sideBarLocTypeParksButton = document.getElementById("MapDropDown2");
		let sideBarLocTypeFoodsButton = document.getElementById("MapDropDown3");
		let sideBarLocTypeBusinessButton = document.getElementById("MapDropDown4");

		let concData = [];
		const loadsnapshot = async () => {
			const snapshot = await firebase.firestore().collection("LiveMapData").get();
			snapshot.forEach((doc) => {
				concData.push(doc.data());
			});
			this.setState({ allListings: concData });
		};

		loadsnapshot();

		sideBarLocTypeAllButton.addEventListener("click", () => {
			window.showMarkers();
			this.setState({ activeMapView: "All" });
		});

		sideBarLocTypeParksButton.addEventListener("click", () => {
			window.showParks();
			this.setState({ activeMapView: "Parks" });
		});

		sideBarLocTypeFoodsButton.addEventListener("click", () => {
			window.setMapOnFood();
			this.setState({ activeMapView: "Food" });
		});

		sideBarLocTypeBusinessButton.addEventListener("click", () => {
			window.setMapOnBusinesses();
			this.setState({ activeMapView: "Business" });
		});

		if (params) {
			console.log("Params Detected");
			console.log(params);

			if (params.includes("Parks")) {
				try {
					setTimeout(() => {
						if (window.showParks) {
							try {
								window.showParks();
								this.setState({ activeMapView: "Parks" });
							} catch (error) {}
						} else {
							setTimeout(() => {
								try {
									window.showParks();
									this.setState({ activeMapView: "Parks" });
								} catch (error) {}
							}, 300);
							setTimeout(() => {
								try {
									window.showParks();
									this.setState({ activeMapView: "Parks" });
								} catch (error) {}
							}, 600);
							setTimeout(() => {
								try {
									window.showParks();
									this.setState({ activeMapView: "Parks" });
								} catch (error) {}
							}, 1000);
						}
					}, 250);
				} catch (error) {}
			}
		}
		if (params.includes("Food")) {
			try {
				setTimeout(() => {
					if (window.showFood) {
						console.log("Attempt1");
						try {
							window.showFood();
							this.setState({ activeMapView: "Food" });
						} catch (error) {}
					} else {
						setTimeout(() => {
							console.log("Attempt2");
							try {
								window.showFood();
								this.setState({ activeMapView: "Food" });
							} catch (error) {}
						}, 300);
						setTimeout(() => {
							console.log("Attempt2");
							try {
								window.showFood();
								this.setState({ activeMapView: "Food" });
							} catch (error) {}
						}, 600);
						setTimeout(() => {
							console.log("Attempt2");
							try {
								window.showFood();
								this.setState({ activeMapView: "Food" });
							} catch (error) {}
						}, 1200);
					}
				}, 1500);
			} catch (error) {}
		}
		if (params.includes("Business")) {
			try {
				setTimeout(() => {
					if (window.showBusinesses) {
						console.log("Attempt1");
						try {
							window.showBusinesses();
							this.setState({ activeMapView: "Business" });
						} catch (error) {}
					} else {
						setTimeout(() => {
							console.log("Attempt2");
							try {
								window.showBusinesses();
								this.setState({ activeMapView: "Business" });
							} catch (error) {}
						}, 300);
						setTimeout(() => {
							console.log("Attempt2");
							try {
								window.showBusinesses();
								this.setState({ activeMapView: "Business" });
							} catch (error) {}
						}, 50);
						setTimeout(() => {
							console.log("Attempt2");
							try {
								window.showBusinesses();
								this.setState({ activeMapView: "Business" });
							} catch (error) {}
						}, 600);
					}
				}, 1000);
			} catch (error) {}
		}
	}
	// var url = new URL(window.location.search);
	// var c = url.searchParams.get("c");
	// console.log(c);

	toggle2(tab) {
		if (this.state.activeTab2 !== tab) {
			this.setState({
				activeTab2: tab,
			});
		}
	}

	toggle1(tab) {
		if (this.state.activeTab1 !== tab) {
			this.setState({
				activeTab1: tab,
			});
		}
	}

	render() {
		const { data } = this.state;

		return (
			<Fragment>
				<Helmet>
					<title>View The Map</title>
					<meta
						name="description"
						content="Filter through categories like restaurants or parks, find amenities, recommendations, comparisons and build a more informed journey with PonoMap."
					/>
					<link rel="canonical" href="https://ponomap.com/services" />
				</Helmet>
				<TransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={1200}
					transitionEnter={false}
					transitionLeave={false}
				>
					<Row>
						<Col style={{ display: "flex", justifyContent: "center" }}>
							<Card
								style={{
									zIndex: 1,
									opacity: 100,
									width: "100%",
									position: "auto",
									boxShadow: "0px 0px 0px 5px rgba(50,50,50, .8)",
									left: "auto",
								}}
							>
								<CardBody>
									<h5>
										The map below lists all current PonoMap information.
										<br />
										<br />
										<b> How it works:</b>
										<br /> <br />
										<li>Use the buttons to filter through different keywords.</li>
										<br />
										<li>
											Click an icon on the map for more information about that listing.
										</li>
									</h5>{" "}
									<br />
									<div>
										<Button
											onClick={() => {
												window.showMarkers();

												this.setState({ activeMapView: "All" });
											}}
											color={this.state.activeMapView === "All" ? "primary" : "secondary"}
										>
											All
										</Button>
										&nbsp;
										<Button
											onClick={() => {
												window.showParks();
												this.setState({ activeMapView: "Parks" });
											}}
											color={
												this.state.activeMapView === "Parks" ? "primary" : "secondary"
											}
										>
											Public Parks
										</Button>
										&nbsp;
										<Button
											onClick={() => {
												window.showFood();
												this.setState({ activeMapView: "Food" });
											}}
											color={this.state.activeMapView === "Food" ? "primary" : "secondary"}
										>
											Food
										</Button>
										&nbsp;
										<Button
											onClick={() => {
												window.showBusinesses();
												this.setState({ activeMapView: "Business" });
											}}
											color={
												this.state.activeMapView === "Business" ? "primary" : "secondary"
											}
										>
											Business
										</Button>
										&nbsp;
										<div
											style={{
												height: "500px",
												boxShadow: "0px 0px 0px 5px rgba(50,50,50, .8)",
											}}
											id="map"
										>
											<SimpleMap />
										</div>
									</div>{" "}
									<br />
									<Col style={{ textAlign: "center" }}>
										<Button
											className="zoom "
											href="/account"
											style={{
												width: "150px",
												backgroundColor: "#3333CC",
												height: "40px",
												alignSelf: "center",
												fontSize: "15px",
											}}
										>
											Add a Listing
										</Button>
									</Col>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col style={{ display: "flex", justifyContent: "center" }}>
							<Card
								style={{
									zIndex: 1,
									opacity: 100,
									width: "100%",
									position: "auto",
									boxShadow: "0px 0px 0px 5px rgba(50,50,50, .8)",
									left: "auto",
								}}
							>
								<CardBody>

									{this.state.allListings &&
										this.state.allListings.map((mapEl) => {
											console.log(mapEl);
											return (
												<div>
													{" "}
													{<Link to={mapEl.Title.replace(/\s/g, "")}>{mapEl.Title}</Link>} <br /> {mapEl.Category} <br /> <br />{" "}
												</div>
											);
										})}
								</CardBody>
							</Card>
						</Col>
					</Row>
					<br />
					<br />
				</TransitionGroup>
			</Fragment>
		);
	}
}
